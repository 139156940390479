/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 2/1/2021
 */

import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../store/actions/table';
import * as eventActions from '../../../store/actions/events';
import ScoreModal from '../../Common/ScoreModal';
import FinalizeCompetitionModal from "../../Common/FinalizeCompetitionModal";
import Nav from "react-bootstrap/Nav";
import Bound from "../../Common/Bound";
import TabulatorTable from "./TabulatorTable";
import {ArrowBack} from "react-ionicons";
import Button from "react-bootstrap/Button";

const ROTATION_TYPE = {
    SET: "SET",
    SPLIT: "SPLIT",
    ROTATING: "ROTATING"
}

class JudgeSingleCompetition extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            score: {},
            showSubmitModal: false
        }
    }

    componentDidMount(props) {
        this.props.getTabulatorCompetition({id: this.props.match.params.competition}, this.props.auth.token._id)
        //this.setTitle();
    }

    componentDidUpdate(props, state) {
        if (this.props.events.competition) {
            if (this.props.events.competition.judgeEvent) {
                if (this.props.events.competition.judgeEvent.completed) {
                    if (this.props.events.competition.judgeEvent.completed.indexOf(this.props.match.params.competition) > -1) {
                        this.props.history.push(`/app/tabulator/${this.props.match.params.id}`);
                        console.log("complete");
                    }
                }
            }
        }
        //this.setTitle();
    }

    setTitle = () => {
        if (this.props.events.competition) {
            if (this.props.events.competition.competition) {
                if (this.props.events.competition.competition._id === this.props.match.params.competition) {
                    document.title = "FeisFWD | " + this.props.events.competition.competition.rowTitle
                        + " - "
                        + this.props.events.competition.competition.columnTitle
                        + " ("
                        + this.props.events.competition.competition.code
                    +")";


                }
            }
        }
    }


    showModal = (score) => {
        this.setState({showModal: true, score: score});
    }

    hideModal = () => {
        this.setState({showModal: false, score: {}});
    }

    showSubmitModal = () => {
        this.setState({showSubmitModal: true});
    }

    hideSubmitModal = () => {
        this.setState({showSubmitModal: false});
    }

    submitCompetition = () => {
        this.props.submitCompetition(this.props.match.params.competition, this.props.auth.token._id);
    }

    menu = (rounds, judges, panelType) => {
        let list = [];
        let overViewTitle = panelType === 'SPLIT' ? 'Final' : 'Overview';
        let overviewPath = panelType === 'SPLIT' ? 'final' : 'overview';

        switch (panelType) {
            case ROTATION_TYPE.SET: 
                let judgeCount = judges[0];         // Set panel so judge count is the same for all rounds
                for (let i = 1; i <= judgeCount; i++) {
                    let tabItem = {
                        title: `Judge ${i}`,
                        path: `/app/tabulation/${this.props.match.params.id}/${this.props.match.params.competition}/${i}`,
                        component: <TabulatorTable/>           // It looks like the screen gets the 'round' value out of the url so setting it here appears to be meaningless
                    }
                    list.push(tabItem);
                }
                break;
            case ROTATION_TYPE.SPLIT: 
                list.push({
                    title: `Rounds 1 & 2`,
                    path: `/app/tabulation/${this.props.match.params.id}/${this.props.match.params.competition}/1`,
                    component: <TabulatorTable/>
                });
                list.push({
                    title: 'R1 & R2 Totals',
                    path: `/app/tabulation/${this.props.match.params.id}/${this.props.match.params.competition}/overview`,
                    component: <TabulatorTable/>
                });
                if (rounds >= 3) {
                    list.push({
                        title: `Round 3`,
                        path: `/app/tabulation/${this.props.match.params.id}/${this.props.match.params.competition}/3`,
                        component: <TabulatorTable/>
                    });
                }
                break;
            case ROTATION_TYPE.ROTATING: 
                for (let i = 1; i <= rounds; i++) {
                    let tabItem = {
                        title: `Round ${i}`,
                        path: `/app/tabulation/${this.props.match.params.id}/${this.props.match.params.competition}/${i}`,
                        component: <TabulatorTable/>           // It looks like the screen gets the 'round' value out of the url so setting it here appears to be meaningless
                    }
                    list.push(tabItem);
                }
                break;
        }

        list.push({
            title: overViewTitle,
            path: `/app/tabulation/${this.props.match.params.id}/${this.props.match.params.competition}/${overviewPath}`,
            component: <TabulatorTable/>
        });

        return list;
    }

    renderItem = (item, i) => {
        if (this.props.events.competition) {
            if (this.props.events.competition.competition) {
                if (this.props.events.competition.competition._id === this.props.match.params.competition) {
                    return (
                        <Nav.Link key={i} to={item.path} as={Link}
                                  className={`tab tab-${i} ${this.props.location.pathname === item.path ? 'active' : ''}`}>{item.title}</Nav.Link>
                    )
                }
            }
        }
    }

    renderContent = () => {
        //console.log("Trying to render Content", this.props.match.params.competition);
        let menuItem = this.menu(
            this.props.events.competition.competition.grade.rounds, 
            this.props.events.competition.competition.grade.scoring.judge_count,
            this.props.events.competition.competition.grade.scoring.judge_rotation_type).filter(item => {
            //console.log("Item", item.path, this.props.location.pathname);
            return item.path === this.props.location.pathname
        });
        if (menuItem.length > 0) {
            return (
                <Bound>
                    {menuItem[0].component}
                </Bound>

            );
        } else {
            return null;
        }
    }


    render() {

        if (this.props.events.competition?.competition?._id === this.props.match.params.competition) {

            let panelType = this.props.events.competition.competition.grade.scoring.judge_rotation_type;
            let judges = this.props.events.competition.competition.grade.scoring.judge_count;
            let rounds = this.props.events.competition.competition.grade.scoring.rounds;
            let tabCount = 4;               // Default to 3 rounds + overview
            let tabClass;

            switch (panelType) {
                case ROTATION_TYPE.SET: 
                    tabCount = judges[0] + 1;       // One tab for each judge plus Overview
                    break;
                case ROTATION_TYPE.SPLIT: 
                    tabCount = 5;                   // R1 + R2, Recall Overview, R3, Final Overview (will be 4 when this is implemented)
                    break;
                case ROTATION_TYPE.ROTATING: 
                    tabCount = rounds + 1;          // One tab for each round plus Overview
                    break;
            }

            tabClass = "tab-row items-" + tabCount;

            return (
                <div className={'page-container page-container-fat'}>
                    <ScoreModal items={this.props.events.competition.entries.length || 0}
                                entry={this.state.score} show={this.state.showModal}
                                hideModal={this.hideModal}/>
                    <FinalizeCompetitionModal submitCompetition={this.submitCompetition}
                                                show={this.state.showSubmitModal}
                                                hideModal={this.hideSubmitModal}/>
                    <div className={'page-header'}>
                        <Row>
                            <Col>
                                <h1>
                                    <Button variant={"flat"} className={"btn-round"} onClick={e => {
                                        this.props.history.push(`/app/tabulator/${this.props.match.params.id}`)
                                    }}><ArrowBack /></Button>
                                    {this.props.events.competition.competition.code} |&nbsp;
                                    {this.props.events.competition.competition.grade.title} |&nbsp;
                                    {this.props.events.competition.competition.rowTitle} |&nbsp;
                                    {this.props.events.competition.competition.columnTitle}
                                </h1>
                            </Col>
                        </Row>
                    </div>
                    <div className={"elevated rounded"}>
                        <div className={"tab-container"}>
                            <div className={tabClass}>
                                {this.menu(rounds, judges, panelType).map((item, i) => {
                                    return this.renderItem(item, i)
                                })}
                                <div className={"tab-highlight"}/>
                                <div className={"tab-highlight-track"}/>
                            </div>

                        </div>
                        <div className={"tab-content ex-pad tabulator-content"}>

                            {this.renderContent()}

                        </div>
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user,
    events: state.events
});

const mapDispatchToProps = dispatch => ({
    getTableData: (query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery("events/getTabulatorCompetition", 0, 999, {}, query, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
    getTabulatorCompetition: (query, token) => {
        dispatch(eventActions.getTabulatorCompetition(query, token));
    },
    submitCompetition: (id, token) => {
        dispatch(eventActions.submitCompetition(id, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(JudgeSingleCompetition));

class JudgeSingleCompetitionContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default JudgeSingleCompetitionContainer;
