import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as tableActions from '../../../store/actions/table';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { FormControl, Checkbox } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as yup from 'yup';
import CategoryPicker from '../../Common/CategoryPicker';
import * as eventActions from '../../../store/actions/events';
import {Checkmark, Trash} from 'react-ionicons';
import TextareaAutosize from 'react-textarea-autosize';
import ReactTooltip from 'react-tooltip';
import humanizeDuration from 'humanize-duration';
import PageLoader from "../../Common/PageLoader";

class TabulatorTwoRoundJudges extends React.Component {
  // This shows all the judges in a single table, with each judge displaying round 1 and 2 results
  constructor(props) {
    super(props);
    this.state = {
      locked: [false, false, false, false],
      items: [],
      editing: [],
      timer: null
    };
  }

  componentDidMount(props) {
    ReactTooltip.rebuild();
  }

  componentWillUnmount() {
    if (this.state.timer) {
      clearInterval(this.state.timer);
      this.setState({ timer: null });
    }
  }

  componentDidUpdate(props, state) {
    if (
      (this.props.table.data.length !== this.state.items.length ||
        this.props.table.updateCount > props.table.updateCount ||
        this.props.table.data.length !== this.state.items.length) &&
      this.props.table.view === ''
    ) {
      this.setState({ items: this.props.table.data });
    }
  }

  emptyJudge = {
    name: 'Not',
    lname: 'Selected',
    email: ''
  };

  toggleLock = (val) => {
    let tab = this.props.events.competition.tabulation;
    // Based on the assumption that this will be used for round 1 and 2 of split comps
    tab['round1Locked'] =!tab['round1Locked'];
    tab['round2Locked'] =!tab['round2Locked'];
    this.props.updateTabulationObject(tab, this.props.auth.token.token._id);
  };

  renderSelector = () => {
    return (
      <tr>
        <td width={400}>
          <Row>
            <Col xs={12}>
              <CategoryPicker
                value={{}}
                tabIndex={1 * this.props.events.competition.entries.length + 1}
                id={'dancerSelector'}
                itemValue={'_id'}
                onChange={(val) => {
                  if (val) {
                    if (
                      this.props.events.competition.entries.filter((en) => en._id === val._id)
                        .length === 0
                    ) {
                      this.props.addDancer(
                        val._id,
                        this.props.match.params.competition,
                        this.props.auth.token._id,
                        (res) => {}
                      );
                    }
                  }
                }}
                remote={true}
                remoteKey={'dancers'}
                urlRoute={`/searchDancerNumber?competition=${this.props.match.params.competition}&event=${this.props.match.params.id}`}
                displayValue={'dancer.name'}
                displayValue3={''}
                imageKey={'image'}
                localState={true}
                displayValue2={'dancer.lname'}
                showDelete={false}
                disableUpdateFetch={true}
              />
            </Col>
          </Row>
        </td>
        <td>
          <Row></Row>
        </td>
      </tr>
    );
  };

  render() {
    let judgeCountArray = this.props.events.competition.competition.grade.scoring.judge_count;
    // Currently (9/7/24) this view is only used for Round 1 & 2 of split comps, so round will always be 1
    let thisRound  = parseInt(this.props.match.params.round);
    let judgeMap = Array(judgeCountArray[thisRound - 1] + 1).fill(1, 0);
    let showSignoffs = this.props.events.competition.competition.grade.judgeSignoffs;
    // Want this to be configurable
    let showComments = true;
    let cellWidth = showComments ? 450 : 300;
    let colSpan = showComments ? 4 : 6;

    return (
      <PageLoader loaded={!this.props.events.loading}>
        <Formik
          onSubmit={this.handleSubmit}
          enableReinitialize
          initialValues={{
            template: this.props.events.competition.template
          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
            setFieldValue
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <div>
                <div className={'tabulator-page-header'}>
                  <Row>
                    <Col xs={9}>
                      <h2>Rounds 1 & 2</h2>
                      <h4>
                        {this.props.events.competition ? (
                          this.props.events.competition.competition ? (
                            this.props.events.competition.competition.grade ? (
                              this.props.events.competition.competition.grade.scoring?.rounds === 1 ? (
                                <span></span>
                              ) : null
                            ) : null
                          ) : null
                        ) : null}
                      </h4>
                    </Col>
                    <Col className={'text-right'}>
                      <button
                        type="button"
                        className="btn btn-flat"
                        onClick={() => this.toggleLock(this.props.match.params.round)}>
                        {this.props.events.competition.tabulation ? (
                          this.props.events.competition.tabulation[
                            'round' + this.props.match.params.round + 'Locked'
                          ] ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="64"
                              height="48"
                              fill="currentColor"
                              className="bi bi-lock-fill"
                              viewBox="0 0 16 16">
                              <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="64"
                              height="48"
                              fill="currentColor"
                              className="bi bi-unlock-fill"
                              viewBox="0 0 16 16">
                              <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2z" />
                            </svg>
                          )
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="64"
                            height="48"
                            fill="currentColor"
                            className="bi bi-unlock-fill"
                            viewBox="0 0 16 16">
                            <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2z" />
                          </svg>
                        )}
                      </button>
                    </Col>
                  </Row>
                </div>

                <Row className={'tabulator-table-pad'} style={{overflowX: "scroll", paddingBottom: "50px"}}>
                  <Table striped hover bordered className={'tab-table'} style={{tableLayout: "fixed"}}>
                    <thead>
                      <tr>
                        <th style={{width: "500px"}}>
                          <b>Dancer</b>
                        </th>
                        {judgeMap.map((n, ni) => {
                          if (ni === judgeMap.length - 1) {
                            return (
                              <th key={ni} width={250}>
                                  <span></span>
                              </th>
                            );
                          } else {
                            return (
                              <th key={ni} width={cellWidth}>
                                <Row>
                                  <Col xs={4}>
                                    <span className={'float-left'}>
                                      {judgeCountArray[thisRound - 1] === 1
                                        ? 'Judge'
                                        : 'Judge ' + (ni + 1).toString()}
                                    </span>
                                  </Col>                  
                                  {(showSignoffs) ?
                                    <React.Fragment>
                                      <Col xs={10}>
                                        <span className={'float-left  pr-1'}>
                                          <b>
                                            Signed off?
                                            <br /> (
                                            <a
                                              href={`/app/pdf/2/${ni}/rank`}
                                              target="_blank"
                                              rel="noopener noreferrer">
                                              Rank
                                            </a>
                                            ,{' '}
                                            <a
                                              href={`/app/pdf/2/${ni}/dancer`}
                                              target="_blank"
                                              rel="noopener noreferrer">
                                              Dancer
                                            </a>
                                            )
                                          </b>
                                        </span>{' '}
                                        <Form.Check
                                          // We're checking round1SignedOff here but in this view R1 and R2 will always be signed off together
                                          checked={this.props.events.competition.tabulation[`round1SignedOff`][ni]}
                                          className={'no-width float-right'}
                                          name={`signedoff${this.props.match.params.round}${ni}`}
                                          onChange={(e) => {
                                            const target = e.target
                                            const checked = target.checked
                                            const name = target.name
                                            this.props.events.competition.tabulation[`round1SignedOff`][ni] = checked;
                                            this.props.events.competition.tabulation[`round2SignedOff`][ni] = checked;
                                            this.setState({
                                                [name]: checked,
                                            });
                                          }}
                                          disabled={this.props.locked}/>
                                      </Col>
                                    </React.Fragment>
                                    : null
                                  }
                                </Row>
                              </th>
                            );
                          }
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        {judgeMap.map((n, ni) => {
                          if (ni === judgeMap.length - 1) {
                            return null;
                          } else {
                            return (
                              <td key={ni}>
                                <Row>
                                  <Col xs={12}>
                                    <CategoryPicker
                                      value={
                                        this.props.events.competition.template['rounds'][
                                          this.props.match.params.round
                                        ].judges[ni]
                                          ? this.props.events.competition.template['rounds'][
                                              this.props.match.params.round
                                            ].judges[ni]
                                          : {}
                                      }
                                      itemValue={'_id'}
                                      onChange={(val) => {
                                        this.props.updateJudge(
                                          this.props.match.params.round,
                                          ni,
                                          val
                                        );
                                      }}
                                      remote={true}
                                      remoteKey={'users'}
                                      urlRoute={'/search'}
                                      displayValue={'name'}
                                      displayValue3={'email'}
                                      imageKey={'image'}
                                      localState={true}
                                      displayValue2={'lname'}
                                      showDelete={false}
                                    />
                                  </Col>
                                  {/* <Col xs={3}>
                                    <FormControl
                                      type={'text'}
                                      className={'microInput'}
                                      placeholder={'Label'}
                                      disabled={
                                        this.props.events.competition.tabulation[
                                          `round${this.props.match.params.round}Locked`
                                        ] ||
                                        this.props.events.competition.tabulation[
                                          `round${this.props.match.params.round}SignedOff`
                                        ][ni]
                                      }
                                      value={
                                        this.props.events.competition.template.rounds[
                                          this.props.match.params.round.toString()
                                        ].judges[ni]
                                          ? this.props.events.competition.template.rounds[
                                              this.props.match.params.round.toString()
                                            ].judges[ni].label
                                          : ''
                                      }
                                      onChange={(e) => {
                                        this.props.updateJudgeLabel(
                                          this.props.match.params.round,
                                          ni,
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </Col> */}
                                </Row>
                              </td>
                            );
                          }
                        })}
                      </tr>

                      <tr>
                        <td>
                          <Row>
                            <Col xs={3}>
                              <b>Name</b>
                            </Col>
                            <Col xs={3}>
                              <b>School</b>
                            </Col>
                            <Col xs={4}>
                              <div className={'text-center'}>
                                <b>
                                  <b>Number</b>
                                </b>
                              </div>
                            </Col>
                            <Col xs={2}></Col>
                          </Row>
                        </td>
                        {judgeMap.map((n, ni) => {
                          if (ni === judgeMap.length - 1) {
                              return (
                                <td key={ni}>
                                  <Row>
                                    <Col xs={6}>
                                      <div className={'text-center'}>
                                        <b>
                                          <b>IP R1 + R2</b>
                                        </b>
                                      </div>
                                    </Col>
                                    <Col xs={6}>
                                      <div className={'text-center'}>
                                        <b>
                                          <b>Rank R1 + R2</b>
                                        </b>
                                      </div>
                                    </Col>
                                  </Row>
                                </td>
                              );
                          } else {
                            return (
                              <td key={ni}>
                                <Row>
                                  <Col xs={colSpan}>
                                    <b>R1 Score</b>
                                  </Col>
                                  <Col xs={colSpan}>
                                    <b>R2 Score</b>
                                  </Col>
                                  {showComments ? 
                                    <Col xs={4}>
                                      <b>Comments</b>
                                    </Col>
                                    : null
                                  }
                                </Row>
                              </td>
                            );
                          }
                        })}
                      </tr>

                      {this.props.events.competition
                        ? this.props.events.competition.entries
                          ? this.props.events.competition.entries.map
                            ? this.props.events.competition.entries.map((comp, i) => {
                                let r1Rel = this.props.events.competition.template.rounds[
                                  '1'
                                ].scores[0].filter((item, index) => {
                                  return item.entryCompetition === comp._id;
                                });
                                let self = this;

                                return (
                                  <tr
                                    key={r1Rel.length > 0 ? r1Rel[0]._id + i : i}
                                    >
                                    <td
                                      data-tip={'Click to assign to another dancer'}
                                      onClick={(e) => {
                                        this.setState(
                                          {editing: [comp._id]},
                                          () => {
                                            let inputElement = document.getElementById(comp._id);
                                            if (inputElement) {
                                                inputElement.focus();
                                            }
                                          });
                                      }}>
                                      {this.state.editing.indexOf(comp._id) > -1 ? (
                                        <Row>
                                          <Col>
                                            <CategoryPicker
                                              id={comp._id}
                                              items={[comp]}
                                              value={comp}
                                              tabIndex={
                                                1 * this.props.events.competition.entries.length + 1
                                              }
                                              itemValue={'_id'}
                                              onChange={(val) => {
                                                if (val) {
                                                  if (
                                                    this.props.events.competition.entries.filter(
                                                      (en) => en._id === val._id
                                                    ).length === 0
                                                  ) {
                                                    this.props.swapDancer(
                                                      val._id,
                                                      comp._id,
                                                      this.props.match.params.competition,
                                                      this.props.auth.token._id
                                                    );
                                                  } else {
                                                    // This dancer already has an entry, meaning the user did not select a new value
                                                    this.props.setEditing([]);
                                                  }
                                                }
                                              }}
                                              remote={true}
                                              remoteKey={'dancers'}
                                              urlRoute={`/searchDancerNumber?competition=${this.props.match.params.competition}&event=${this.props.match.params.id}`}
                                              displayValue={'dancer.name'}
                                              displayValue3={''}
                                              imageKey={'image'}
                                              localState={true}
                                              displayValue2={'dancer.lname'}
                                              showCancel={true}
                                              onCancel={(e) => {
                                                self.setState(
                                                  {
                                                    editing: []
                                                  },
                                                  () => {}
                                                );
                                              }}
                                              disableUpdateFetch={true}
                                              selectOnBlur={true}
                                            />
                                          </Col>
                                        </Row>
                                      ) : (
                                        <Row>
                                          <Col xs={3}>
                                            {comp.dancer.name} {comp.dancer.lname}
                                          </Col>
                                          <Col xs={3}>
                                            {comp.dancer.school ? comp.dancer.school.name : ''}
                                          </Col>
                                          <Col xs={4}>
                                            <div className={'text-center'}>
                                              <b>{comp.dancerNumber || 0}</b>
                                            </div>
                                          </Col>
                                          <Col xs={2}>
                                            <Button variant={'flat'} className={"btn-small-padding"} onClick={(e) => {

                                              e.stopPropagation();
                                              e.preventDefault();
                                              // Remove dancer from comp
                                              this.props.removeDancer(comp._id, comp.competition._id, this.props.auth.token._id, null)

                                            }}><Trash fontSize={'18px'} {...{color: 'rgba(0,0,0,.5)'}} /></Button>
                                          </Col>
                                        </Row>
                                      )}
                                      <Col xs={6}>
                                        <div className={'text-center'}></div>
                                      </Col>
                                    </td>
                                    {judgeMap.map((n, ni) => {
                                      if (ni === judgeMap.length - 1) {
                                        let rel = [];
                                        rel = this.props.events.competition.template.rounds[
                                          '1'
                                        ].scores[0].filter((item, index) => {
                                          return item.entryCompetition === comp._id;
                                        });

                                        return (
                                          <td
                                            key={(1 + i) * (1 + ni)}>
                                            <Row>
                                              <Col xs={6}>
                                                <span className={'slim'}>
                                                  <div className={'text-center'}>
                                                    {rel.length > 0 && rel[0]['finalTwoRoundIp'] >= 0 ? rel[0]['finalTwoRoundIp'].toFixed(2) : ''}
                                                  </div>
                                                </span>
                                              </Col>
                                              <Col xs={6}>
                                                <div className={'text-center'}>
                                                  <b>{rel.length > 0 && rel[0]['totalTwoRoundRank'] >= 0 ? 
                                                    rel[0]['totalTwoRoundRank'] + (rel[0]['final2roundTied'] ? 'T' : '') : ''}</b>
                                                </div>
                                              </Col>
                                            </Row>
                                          </td>
                                        );

                                      } else {
                                        // We only need to fetch the round 1 record because R1 and R2 records are the same record in a SPLIT panel
                                        let rel = this.props.events.competition.template.rounds["1"]
                                          .scores[ni]
                                          ? this.props.events.competition.template.rounds["1"].scores[
                                              ni
                                            ].filter((item, index) => {
                                              return item.entryCompetition === comp._id;
                                            })
                                          : [{}];

                                        let className1 = '';
                                        let className2 = '';
                                        let score1 = parseFloat(rel.length > 0 ? rel[0][`round1Score`] : 0);
                                        let score2 = parseFloat(rel.length > 0 ? rel[0][`round2Score`] : 0);

                                        if (score1 > -1) {
                                          if (score1 >= 0 && score1 <= 100) {
                                            className1 = 'is-valid';
                                          } else {
                                            className1 = 'is-invalid';
                                          }
                                        }
                                        if (score2 > -1) {
                                          if (score2 >= 0 && score2 <= 100) {
                                            className2 = 'is-valid';
                                          } else {
                                            className2 = 'is-invalid';
                                          }
                                        }

                                        return (
                                          <td key={(1 + i) * (1 + ni)}>
                                            <Row>
                                              <Col xs={colSpan}>
                                                <FormControl
                                                  tabIndex={ni + 1}
                                                  type={'number'}
                                                  className={`microInput ${className1}`}
                                                  placeholder={'Score'}
                                                  id={rel[0] ? rel[0]._id : ''}
                                                  disabled={
                                                    this.props.events.competition.tabulation[
                                                      `round1Locked`
                                                    ] ||
                                                    this.props.events.competition.tabulation[
                                                      `round1SignedOff`
                                                    ][ni]
                                                  }
                                                  value={score1 > -1 ? score1 : ''}
                                                  onChange={(e) => {
                                                    this.props.updateScore(
                                                      rel[0],
                                                      parseFloat(e.target.value),
                                                      ni,
                                                      1
                                                    );
                                                    setFieldValue('template', {
                                                      ...this.props.events.competition.template,
                                                      rounds: {
                                                        ...this.props.events.competition.template
                                                          .rounds,
                                                        ["1"]: {
                                                          ...this.props.events.competition.template
                                                            .rounds["1"],
                                                          scores:
                                                            this.props.events.competition.template.rounds[
                                                              "1"
                                                            ].scores.map((score, index) => {
                                                              return score.map((scoreItem) => {
                                                                if (scoreItem._id === rel[0]._id) {
                                                                  return {
                                                                    ...scoreItem,
                                                                    ['round1Score']:
                                                                      e.target.value
                                                                  };
                                                                } else {
                                                                  return scoreItem;
                                                                }
                                                              });
                                                            })
                                                        }
                                                      }
                                                    });
                                                  }}
                                                />
                                              </Col>

                                              <Col xs={colSpan}>
                                                <FormControl
                                                  tabIndex={ni + 1}
                                                  type={'number'}
                                                  className={`microInput ${className2}`}
                                                  placeholder={'Score'}
                                                  id={rel[0] ? rel[0]._id : ''}
                                                  disabled={
                                                    this.props.events.competition.tabulation[
                                                      `round2Locked`
                                                    ] ||
                                                    this.props.events.competition.tabulation[
                                                      `round2SignedOff`
                                                    ][ni]
                                                  }
                                                  value={score2 > -1 ? score2 : ''}
                                                  onChange={(e) => {
                                                    this.props.updateScore(
                                                      rel[0],
                                                      parseFloat(e.target.value),
                                                      ni,
                                                      2
                                                    );
                                                    setFieldValue('template', {
                                                      ...this.props.events.competition.template,
                                                      rounds: {
                                                        ...this.props.events.competition.template
                                                          .rounds,
                                                        ["2"]: {
                                                          ...this.props.events.competition.template
                                                            .rounds["2"],
                                                          scores:
                                                            this.props.events.competition.template.rounds[
                                                              "2"
                                                            ].scores.map((score, index) => {
                                                              return score.map((scoreItem) => {
                                                                if (scoreItem._id === rel[0]._id) {
                                                                  return {
                                                                    ...scoreItem,
                                                                    ['round2Score']:
                                                                      e.target.value
                                                                  };
                                                                } else {
                                                                  return scoreItem;
                                                                }
                                                              });
                                                            })
                                                        }
                                                      }
                                                    });
                                                  }}
                                                />
                                              </Col>

                                              {showComments ?
                                                <Col xs={4}>
                                                <TextareaAutosize
                                                  tabIndex={ni + 1}
                                                  type={'text'}
                                                  className={'form-control microInput'}
                                                  placeholder={'Comments'}
                                                  id={rel[0] ? rel[0]._id : ''}
                                                  disabled={
                                                    this.props.events.competition.tabulation[
                                                      `round${this.props.match.params.round}Locked`
                                                    ] ||
                                                    this.props.events.competition.tabulation[
                                                      `round${this.props.match.params.round}SignedOff`
                                                    ][ni]
                                                  }
                                                  value={
                                                    rel[0]
                                                      ? rel[0]['round1Comments'] || ''
                                                      : ''
                                                  }
                                                  onChange={(e) => {
                                                    this.props.updateComments(
                                                      rel[0],
                                                      e.target.value,
                                                      ni,
                                                      this.props.match.params.round
                                                    );
                                                    setFieldValue('template', {
                                                      ...this.props.events.competition.template,
                                                      rounds: {
                                                        ...this.props.events.competition.template
                                                          .rounds,
                                                        ["1"]: {
                                                          ...this.props.events.competition.template
                                                            .rounds["1"],
                                                          scores:
                                                            this.props.events.competition.template.rounds[
                                                              "1"
                                                            ].scores.map((score, index) => {
                                                              return score.map((scoreItem) => {
                                                                if (scoreItem._id === rel[0]._id) {
                                                                  return {
                                                                    ...scoreItem,
                                                                    ['round1Comments']:
                                                                      e.target.value
                                                                  };
                                                                } else {
                                                                  return scoreItem;
                                                                }
                                                              });
                                                            })
                                                        }
                                                      }
                                                    });
                                                  }}
                                                />
                                              </Col>
                                              : null                                              
                                              }
                                            </Row>
                                          </td>
                                        );
                                      }
                                    })}
                                  </tr>
                                );
                              })
                            : null
                          : null
                        : null}

                      {this.renderSelector()}
                    </tbody>
                  </Table>
                </Row>
                <div className={'text-right ex-pad'}>
                  <Button
                    variant={'primary'}
                    onClick={(e) => {
                      e.preventDefault();

                      this.props.updateTabScore(
                        this.props.events.competition.template,
                        this.props.events.competition.tabulation,
                        this.props.auth.token._id,
                        false,
                        this.props.events.competition.competition._id,
                        this.props.events.competition.competition.grade.scoring.rounds
                      );
                    }}>
                    <Checkmark /> Save
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </PageLoader>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  table: state.table,
  user: state.user,
  events: state.events,
  single: state.single,
  tabulator: state.tabulator
});

const mapDispatchToProps = (dispatch) => ({
  getTableData: (view, skip, limit, sort, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
  },
  getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
  },
  updateTabScore: (template, tabulation, token, showAutosave, competitionId, rounds) => {
    dispatch(eventActions.updateTabulatorScore(template, tabulation, token, showAutosave, competitionId, rounds));
  },
  updateScore: (entry, value, judgeIndex, round) => {
    dispatch(eventActions.updateScore(entry, value, judgeIndex, round));
  },
  updateComments: (entry, value, judgeIndex, round) => {
    dispatch(eventActions.updateComments(entry, value, judgeIndex, round));
  },
  updateJudge: (round, judge, val) => {
    dispatch(eventActions.updateJudge(round, judge, val));
  },
  updateJudgeLabel: (round, judge, val) => {
    dispatch(eventActions.updateJudgeLabel(round, judge, val));
  },
  updateTabulationObject: (tab, token) => {
    dispatch(eventActions.updateTabulationObject(tab, token));
  },
  updateEntryScore: (entry, round, key, val) => {
    dispatch(eventActions.updateEntryScore(entry, round, key, val));
  },
  addDancer: (id, competition, token, cb) => {
    dispatch(eventActions.tabulatorAddDancer(id, competition, token, cb));
  },
  swapDancer: (newId, oldId, competition, token, cb) => {
    dispatch(eventActions.tabulatorSwapDancer(newId, oldId, competition, token, cb));
  },
  removeDancer: (entryCompetitionId, competition, token, cb) => {
    dispatch(eventActions.tabulatorRemoveDancer(entryCompetitionId, competition, token, cb));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(TabulatorTwoRoundJudges));

class TabulatorTwoRoundJudgesContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default TabulatorTwoRoundJudgesContainer;
