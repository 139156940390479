import React from 'react';

import { parseInt} from 'lodash';
import PDF from "./PDFComponent";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as tableActions from '../../../store/actions/table';


const PDFPage = (props) => {

  const label = "JUDGE SIGN OFF"
  const roundNumber = parseInt(props.match.params.round);
  const judgeNumber = parseInt(props.match.params.judge);
  const sorting = props.match.params.sortby ? props.match.params.sortby : "";

  const roundName = "round" + (roundNumber)

  const events = props.events;
  if (!events) return null;
  const event = events.event;
  const round = events.competition.template.rounds[roundNumber];
  var scores = round.scores[judgeNumber];
  const judge = round.judges[judgeNumber];
  const split = events.competition.competition.grade.scoring.judge_rotation_type === 'SPLIT';
  const set = events.competition.competition.grade.scoring.judge_rotation_type === 'SET';
  const recall = events.competition.competition.grade.recallType !== 'NONE';
  const roundCount = events.competition.competition.grade.rounds;
  console.log("roundNumber: " + roundNumber);
  console.log("judgeNumber: " + judgeNumber);
  console.log("round.judges: " + round.judges);

  const competitionName = events.competition.competition.code + " | " +
    events.competition.competition.grade.title + " | " +
    events.competition.competition.rowTitle + " | " +
    events.competition.competition.columnTitle

  const titles = [
    props.events.competition.competition.grade.event.name,
    competitionName,
    "Round " + (roundNumber),
    judge.name + " " + judge.lname,
      ""
  ]

  const subtitles = [
      ""
  ]

  let headers = [];
  let rankSortField;

  if (split || set) {
    if (split && roundNumber === 2) {
      headers = [
        "Dancer",
        "Round 1",
        "Round 2",
        "Subtotal",
        "Rank"
      ];
      rankSortField = "round2Rank";
    } else if (roundCount === 1) {
      headers = [
        "Dancer",
        "Raw Score",
        "Rank"
      ]
      rankSortField = "judgeFinalRank";
    } else {
      headers = [
        "Dancer",
        "Round " + roundNumber,
        "Rank"
      ];
      rankSortField = "round" + roundNumber + "Rank";
    }
  } else {
    headers = [
      "Dancer",
      "Raw Score",
      "Rank"
    ]
    rankSortField = "judgeFinalRank";
  }


  scores = scores.map(s => {
    let rel = events.competition.entries.filter(item => item._id === s.entryCompetition);
    //console.log("Rel", rel, events.competition.entries[i]);
    let d = {};

    if (rel.length > 0) {
      d = rel[0];
    }
    return {
      ...s,
      dancerNumber: d.dancerNumber
    }
  })


  if (sorting === "rank") {
    scores = scores.sort((a, b) => {
      return a[rankSortField] - b[rankSortField];
    })
    subtitles[0] = "Sorted by Rank";
  } else if (sorting === "dancer") {
    scores = scores.sort((a, b) => {


      return a["dancerNumber"] - b["dancerNumber"]
    })
    subtitles[0] = "Sorted by Dancer Number";
  }

  //console.log("SORT", scores);

  let chunks = [];
  var i,j, temporary, chunk2 = 20;
  for (i = 0,j = scores.length; i < j; i += chunk2) {
    temporary = scores.slice(i, i + chunk2);
    chunks.push(temporary);
    // do whatever
  }

  console.log("tem", chunks, scores);


  let tables = [];

  for(var chunk of chunks) {
    var content = chunk.map((score, i) => {
      let rel = events.competition.entries.filter(item => item._id === score.entryCompetition);
      //console.log("Rel", rel, events.competition.entries[i]);
      let s = {};


      if (rel.length > 0) {
        s = rel[0];
      }

      if (split || set) {
        if (split && roundNumber === 2) {
          return [
            s.dancerNumber ? s.dancerNumber : 0,
            score["round1Score"],
            score["round2Score"],
            score["round1Score"] + score["round2Score"],
            score["round2Rank"] + (score["judgeRound2Tied"] ? "T" : "")
          ];
        } else if (roundNumber === 3) {
          if (!recall || score["recall"]) {
            return [
              s.dancerNumber ? s.dancerNumber : 0,
              score["round3Score"],
              score["round3Rank"] + (score["judgeRound3Tied"] ? "T" : "")
            ];
          } else {
            // Dancer did not recall, omit from report
            return;
          }
        } else {
          return [
            s.dancerNumber ? s.dancerNumber : 0,
            score[roundName + "Score"],
            score[roundName + "Rank"] + (score["judgeRound" + roundNumber + "Tied"] ? "T" : "")
          ];
        }
      } else {
        if (roundNumber === 3 && recall && !score["recall"]) {
          // Dancer did not recall, omit from report
          return;
        } else {
          return [
            s.dancerNumber ? s.dancerNumber : 0,
            score[roundName + "Score"],
            score[roundName + "Rank"] + (score["judgeRound" + roundNumber + "Tied"] ? "T" : "")
          ];
        }
      }
    });

    tables.push({
      headers,
      content
    });
  }






  const table = {
    headers,
    content
  }

  const pdfData = {
    table,
    tables,
    titles,
    subtitles,
    image: props.events.competition.competition.grade.event.image.path,
    label: label
  }

  return (
    <div style={{ height: "100vh" }}>

      <PDF pdfData={pdfData} />
    </div>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  table: state.table,
  user: state.user,
  events: state.events
});


const mapDispatchToProps = dispatch => ({
  getTableData: (view, skip, limit, sort, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
  },
  getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
  },
});


const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(PDFPage));

class PDFPageContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    )
  }
}

export default PDFPageContainer;
