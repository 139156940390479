import React from 'react';

import PDF from "./ResultPDFComponent";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as tableActions from '../../../store/actions/table';

import moment from 'moment';
import { isPlaceholderType } from '@babel/types';

const PDFPage = (props) => {
  const roundNumber = 1; // These are final results, r1 will have all competitors, so use r1 scores for the map function
  const judgeNumber = 0;  // Not dependent on any specific judge
  const final = props.match.params.final === "true" ? true : false;


  const events = props.events;
  if (!events) return null;
  const rounds = events.competition.template.rounds;
  const round = rounds[roundNumber];
  var scores = round.scores[judgeNumber];
  // var scores = round.scores[judgeNumber].filter(item => item.recall);
  var judges = []

  Object.values(rounds).forEach((round) => round.judges.forEach((judge) => { if (judge.name) {judges.push(`${judge.name} ${judge.lname}`)}}));

  let grade = events.competition.competition.grade;
  let comp = events.competition.competition;
  let panelType = events.competition.competition.grade.scoring.judge_rotation_type;
  let roundCount = events.competition.competition.grade.scoring.rounds;
  let judgeCountArray = events.competition.competition.grade.scoring.judge_count;
  let judgeCount1 = judgeCountArray[0];
  let judgeCount2 = (roundCount >= 2) ? judgeCountArray[1] : 0;
  let judgeCount3 = (roundCount >= 3) ? judgeCountArray[2] : 0;
  let recall = events.competition.competition.grade.recallType !== 'NONE';
  let teams = events.competition.competition.grade.type.toLowerCase().includes('team');
  let showIp = events.competition.competition.grade.scoring.showIp || false;

  let ageTitle = '';

  for (var col of grade.columns) {
    if (col.id.toString() === comp.column) {
      ageTitle = col.title;
    }
  }

  const competitionName = events.competition.competition.code + " | " +
    events.competition.competition.grade.title + " | " +
    events.competition.competition.rowTitle + " | " +
    ageTitle

  const titles = [
    // event.name,
    competitionName,
    `${moment(events.event.judgingEnd).format('MMMM Do YYYY')}`
  ]

  // Pretty sure this is not used, but it is passed in to PDF
  const headers = [
    "Dancer Number",
    "Name",
    "School",
    "Final IP All rounds",
    "Final Rank",
    "WQ",
    "NQ",
  ]


  let r = props.events.competition.competition.grade.rounds;
  let entriesMain = events.competition.entries;
  let entries = [];
  let scores1 = JSON.parse(JSON.stringify(events.competition.template.rounds["1"].scores[0]));

  scores1 = scores1.sort((a, b) => b.finalIp - a.finalIp);
  scores1.forEach(item => {
    let entry = entriesMain.filter(it => it._id === item.entryCompetition)[0];
    entries.push(entry);
  })

  // When we sort the results, we no longer have field names, just values. 
  // We will need to track which field in the array is the final rank to be sorted on
  let sortIndex = 0;

  // We need to know what column to check for 'Dancers below this line did not...' text
  let placedIndex = 0;
  let noun = teams ? "Teams" : "Dancers";
  let placedText = (final || panelType === 'SET') ? `${noun} below this line did not place` : `${noun} below this line did not recall`;

  var content = scores.map((score, i) => {
    let rel = entries.filter(item => item._id === score.entryCompetition);
    let s = {};

    if (rel.length > 0) {
      s = rel[0];
    }

        let r1 = []

        events.competition.template.rounds["1"].scores.forEach((item, index) => {
          item.forEach(en => {
            if (en.entryCompetition === s._id) {
              r1.push(en);
            }
          })
        });

        let r2 = []
        events.competition.template.rounds["2"].scores.forEach((item, index) => {
          item.forEach(en => {
            if (en.entryCompetition === s._id) {
              r2.push(en);
            }
          })
        });

        let r3 = [];

        events.competition.template.rounds["3"].scores.forEach((item, index) => {
          item.forEach(en => {
            if (en.entryCompetition === s._id) {
              r3.push(en);
            }
          })
        });
        // console.log("COMP", comp)
        const dancer = s.dancer;
        const nameString = teams ? s.dancer?.name : s.dancer?.name + " " + s.dancer?.lname;
        let res = [
          s.dancerNumber ? s.dancerNumber : 0,
          nameString,
          s.dancer ? s.dancer.school?.name : "",];
          let rd3totalString;

        if (final) {
          for(let i = 0; i < judgeCount3; i++) {
            rd3totalString = recall && r3[i].recall === false ? "N/A-0" : r3[i].judgeFinalRank + "/" + r3[i].judgeFinalIp.toFixed(2);
            res.push(`${r3[i].round3Score > 0 ? r3[i].round3Score : 0}`);
            res.push(`${rd3totalString}`);
          }

          res = res.concat([
            `${r3[0].finalTwoRoundIp.toFixed(2)}`,
            `${r3[0].totalRound3Ip.toFixed(2)}`,
            `${r3[0].finalIp.toFixed(2)}`,
            `${r3[0].finalRank}`,
            `${r3[0].placed ? 'Y' : 'N'}`
          ])

          // finalRank is second last field, so set sortIndex
          sortIndex = res.length - 2;

          // Placed column is last
          placedIndex = res.length - 1;

          // Add WQ and NQ fields if relevant
          if (events.competition.competition.grade.nationalQualifier) {
            res.push(`${(r3[0]['nq'] === true) ? 'Y' : 'N'}`);
          }
          if (events.competition.competition.grade.worldQualifier) {
            res.push(`${(r3[0]['wq'] === true) ? 'Y' : 'N'}`);
          }

        } else if (panelType === 'ROTATING') {
          for(let r = 1; r <= roundCount; r++) {
            let roundArray;
            let scoreField;
            let ipField;
            let totalIPField;
            switch (r) {
              case 1:
                roundArray = r1;
                scoreField = "round1Score";
                ipField = "round1Ip";
                totalIPField = "totalRound1Ip";
                break;
              case 2:
                roundArray = r2;
                scoreField = "round2Score";
                ipField = "round2Ip";
                totalIPField = "totalRound2Ip";
                break;
              case 3:
                roundArray = r3;
                scoreField = "round3Score";
                ipField = "round3Ip";
                totalIPField = "totalRound3Ip";
                break;
            }
            for(let i = 0; i < judgeCount1; i++) {
              if (r ===  3) {
                rd3totalString = r3[i].recall === false ? "" : r3[i].round3Score + "/" + r3[i].round3Ip.toFixed(2);
                res.push(`${rd3totalString}`);
              } else {
                res.push(`${roundArray[i][scoreField] + "/" + roundArray[i][ipField].toFixed(2)}`);
              }
            }
            
            // Total IP + rank for the round
            res.push(`${roundArray[0][totalIPField].toFixed(2)}`);

            // Add additional IP fields (rounds 2 and 3 only) and rank
            // TODO: not sure we're properly handling 2 round rotating comps, if that's even a thing.
            switch (r) {
              case 1:
                res.push(`${roundArray[0]['totalRound1Rank'] + ((roundArray[0]['totalRound1Tied'] === true) ? 'T' : '')}`);
                break;
              case 2:
                res.push(`${roundArray[0]['finalTwoRoundIp']}`);
                res.push(`${roundArray[0]['totalTwoRoundRank'] + ((roundArray[0]['final2roundTied'] === true) ? 'T' : '')}`);
                
                // Add recall column if appropriate for this comp
                if (recall) {
                  res.push(`${(roundArray[0]['recall'] === true) ? 'Y' : 'N'}`);

                  // Placed (or in this case 'Recall') column is last
                  placedIndex = res.length - 1;
                }
                break;
              case 3:
                res.push(`${roundArray[0]['finalIp']}`);
                res.push(`${roundArray[0]['finalRank'] + ((roundArray[0]['tied'] === true) ? 'T' : '')}`);
                res.push(`${roundArray[0].placed ? 'Y' : 'N'}`);

                // finalRank is second last field, so set sortIndex
                sortIndex = res.length - 2;

                // Add WQ and NQ fields if relevant
                if (events.competition.competition.grade.nationalQualifier) {
                  res.push(`${(roundArray[0]['nq'] === true) ? 'Y' : 'N'}`);
                }
                if (events.competition.competition.grade.worldQualifier) {
                  res.push(`${(roundArray[0]['wq'] === true) ? 'Y' : 'N'}`);
                }

                // wmh?
                //res.push(`${((roundArray[0]['wmh'] === true)) ? 'Y' : 'N'}`);
                break;
            }
          }
        } else if (panelType === "SET") {
          // Per judge fields
          for(let v = 0; v < judgeCount1; v++) {

            res.push(`${r1[v].round1Score}`);

            if (r >= 2) {
              res.push(`${r2[v].round2Score}`);
            }
            if (r >= 3) {
              res.push(`${r3[v].round3Score}`);
            }

            // Add judge total if this was more than 1 rd
            if (r >= 2) {
              res.push(`${r1[v].judgeTotalScore}`);
            }

            // Add IP if appropriate
            if (showIp) {
              res.push(`${r1[v].judgeFinalIp.toFixed(2)}`);
            }
          }

          // Final IP, Rank, and Placed
          if (judgeCount1 >= 2 && showIp) {
            res.push(`${r1[0].finalIp.toFixed(2)}`);
          }
          res.push(`${r1[0].finalRank + ((r1[0].tied === true) ? 'T' : '')}`);

          // Sorting field is last in array currently
          sortIndex = res.length - 1;

          // Add placed column
          res.push(`${r1[0].placed ? 'Y' : 'N'}`);

          // Placed column is last
          placedIndex = res.length - 1;

          // Add WQ and NQ fields if relevant
          if (events.competition.competition.grade.nationalQualifier) {
            res.push(`${(r1[0]['nq'] === true) ? 'Y' : 'N'}`);
          }
          if (events.competition.competition.grade.worldQualifier) {
            res.push(`${(r1[0]['wq'] === true) ? 'Y' : 'N'}`);
          }

          // wmh?
          //res.push(`${((roundArray[0]['wmh'] === true)) ? 'Y' : 'N'}`);

        } else {  // Split panel, rounds 1 + 2
          for(let i = 0; i < judgeCount1; i++) {
            if (r === 1) {
              res.push(`${r1[i].round1Score}`);
            } else {
              res.push(`${r1[i].round1Score}` + "/" +  `${r1[i].round2Score}`);
            }
              
            if (r >= 3 && panelType !== 'SPLIT') {
              res.push(`${r3[i].round3Score}`);
            }
  
            if (r > 1) {
              res.push(`${r1[i].judgeTotalScore}`);
            }
            res.push(`${r1[i].judgeFinalRank}` + "/" +  `${r1[i].judgeFinalIp.toFixed(2)}`);
  
          }

          res = res.concat([
            `${r1[0].finalTwoRoundIp.toFixed(2)}`,
            `${r1[0].totalTwoRoundRank}`
          ])

          // Sorting field is last in array currently
          sortIndex = res.length - 1;

          if (recall) {
            res.push(`${r1[0].recall ? 'Y' : 'N'}`);

            // Placed (or in this case 'Recall') column is last
            placedIndex = res.length - 1;
          }
        }


    console.log("RES", res);
    return res;

  });

  content = content.sort((a, b) => {
    let val1 = a[sortIndex].replace('T','');
    let val2 = b[sortIndex].replace('T','');
    return val1 - val2
  })

  if (r === 2) {
    content = content.sort((a, b) => {
      return a[16] - b[16]
    })
  }
  let addedPlacedText = false;

  // Add the 'Dancers below this line did not...' text
  for(let c of content) {
    let index = content.indexOf(c);
    
    if (content[index + 1]) {
      // There is a record after this, so if this dancer placed and the next did not, add the text here
      if (c[placedIndex] === "Y" && content[index + 1][placedIndex] === "N" && !addedPlacedText) {
        console.log("C", c);
        addedPlacedText = true;
        content.splice(index + 1, 0, [placedText]);
      }
    }
  }
  

  console.log("CONTENTS", content);


  let tables = [];
  var i,j, temporary, chunk = 15;
  for (i = 0,j = content.length; i < j; i += chunk) {
    temporary = content.slice(i, i + chunk);
    tables.push({
      headers,
      content: temporary
    });
    // do whatever
  }


  console.log("Tables", tables);



  const table = {
    headers,
    content
  }

  const pdfData = {
    table,
    titles,
    tables,
    events: props.events,
    image: props.events.competition.competition.grade.event.image.path,
    eventName: props.events.competition.competition.grade.event.name,
    final: final
  }

  return (
    <div style={{ height: "100vh" }}>

      <PDF pdfData={pdfData} judges={judges} />
    </div>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  table: state.table,
  user: state.user,
  events: state.events
});


const mapDispatchToProps = dispatch => ({
  getTableData: (view, skip, limit, sort, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
  },
  getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
  },
});


const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(PDFPage));

class PDFPageContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    )
  }
}

export default PDFPageContainer;
